import { FaLinkedinIn as LinkedinIcon } from 'react-icons/fa';
import { IoLogoGithub as GithubIcon } from 'react-icons/io';

const SocialMedia = () => {
  return (
    <div className="mt-2 flex justify-center items-center ">
      <a
        className="social-icons"
        href="https://www.linkedin.com/in/elo%C3%AFc-ah-hing-28422326/"
        aria-label="linkedin"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkedinIcon className="icon cursor-pointer text-2xl mr-6" />
      </a>
      <a
        className="social-icons"
        href="https://github.com/eloic-8"
        aria-label="github"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GithubIcon className="icon cursor-pointer text-2xl mr-6" />
      </a>
    </div>
  );
};

export default SocialMedia;
