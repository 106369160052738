import SocialMedia from '../components/homepage/SocialMedia';
import dynamic from 'next/dynamic';
import { Suspense } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const DynamicHeader = dynamic(() => import('../components/homepage/home'), {
  suspense: true
});

const Index = () => {
  return (
    <>
      <Suspense fallback={'Loading...'}>
        <DynamicHeader />
      </Suspense>
      <SocialMedia />
    </>
  );
};

export const getStaticProps = async ({ locale }: any) => ({
  props: {
    ...(await serverSideTranslations(locale, ['index']))
  }
});

export default Index;
